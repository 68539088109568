<template>
  <v-container
    fluid
    class="py-0"
  >
    <v-row>
      <v-col
        cols="12"
        class="py-0 px-0"
      >
        <vue-cal
          :time-from="10 * 60"
          :time-to="24 * 60"
          :time-step="15"
          show-week-numbers
          :time-cell-height="timeCellHeight"
          :events="events"
          :active-view="currentView"
          :on-event-click="eventClick"
          :click-to-navigate="false"
          :dblclick-to-navigate="true"
          @view-change="changeView"
          @ready="scrollToCurrentTime"
        >
          <template
            v-slot:event="{event}"
            v-if="currentView === 'week'"
          >
            <div>{{ event.title }}</div>
          </template>
        </vue-cal>
        <v-overlay v-if="isGettingBookings">
          <v-progress-circular indeterminate />
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

export default {
	name: "BookingCalendar",
	components: {
		VueCal
	},
	props: {
		scrollToTime: {
			type: Boolean
		}
	},
	data() {
		return {
			currentView: 'day',
			type: null,
			timeCellHeight: 70,
		}
	},
	methods: {
		scrollToCurrentTime() {
			let q = document.querySelector('.vuecal__now-line')
			if(q) {
				document.querySelector('.vuecal__now-line').scrollIntoView({ behavior:'smooth', block: 'center' })
			}
		},
		changeView(e) {
			if(e.view === 'day' || e.view === 'week') {
				const start = e.startDate.getTime()
				const end = e.endDate.getTime()
				this.getBookingsByDate(start, end)
			}
			this.currentView = e.view
			this.scrollToCurrentTime()
		},
		eventClick(e) {
			if(e && e.booking) {
				this.$emit('editBooking', e.booking)
			}
		},
		getBookingsByDate(startTimeInMillis, endTimeInMillis) {

			let payload = {
				startTimeInMillis: startTimeInMillis,
				endTimeInMillis: endTimeInMillis
			}

			this.$store.dispatch('getBookingsByDate', payload)
		},
		parseDate(date) {
			if(date && date.toString().length === 10) {
				date = date*1000
			}
			let d = new Date(date)
			return d.toISOString().substr(0,10) + ' ' + d.toTimeString().substr(0,5)
		},
	},
	computed: {
		events: function() {
			const bookings = this.$store.state.bookings
			if (bookings) {
				return bookings.map(booking => {
					let bCustomer = ''

					if (booking.customer && booking.customer.address && booking.customer.address.name_line) {
						bCustomer = booking.customer.address.name_line.split(' ')[0]
					}

					if (booking.status === null || booking.status === undefined || booking.status === '0') {
						return null
					}

					return {
						start: this.parseDate(booking.startsAtTime),
						end: this.parseDate(booking.endsAtTime),
						title: (bCustomer ? bCustomer + ' - ' + (booking.item ? booking.item.title : '') : ''),
						booking: booking,
						class: 'booking'
					}
				}).filter(i => i)
			} else {
				return []
			}
		},
		isGettingBookings: function() {
			return this.$store.state.isUpdatingBookings
		},
	},
	watch: {
		scrollToTime(value) {
			if(value) {
				this.scrollToCurrentTime()
			}
		}
	}
}
</script>

<style scoped>

</style>